import { Delete } from "@mui/icons-material"
import { Backdrop, Box, Fade, Modal, Typography, Button } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { enqueueSnackbar } from "notistack"
import { useIntl } from "react-intl"
import { axiosClass } from "../../../api/api.config"
import { useState } from "react"
import { ConfirmDeleteUser } from "../../mui-components/modal/ConfirmDeleteUser"
import { useStoreProv } from "../../../utils/zustand/store"

export default function TableWallets({ getWallet, setGetWallet }) {
  const intl = useIntl()
  const {openModalConfirm, setOpenModalConfirm, userDeleteModal, setUserDeleteModal} = useStoreProv()


  const putUser = (wallet) => {
    const walletAlertSuccess = intl.formatMessage({
      id: "free.wallet.remove",
      defaultMessage: "Wallet has successfully removed!"
    })

    const walletAlertError = intl.formatMessage({
      id: "free.wallet.remove.error",
      defaultMessage: "An error has ocurred, please try again"
    })

    axiosClass
      .put("/setwallet", { wallet: wallet })
      .then((res) => {
        if (res.status === 200) {
          const getAFeeling = getWallet.findIndex(
            (index) => index.wallet === wallet
          )

          const updatedWallet = [...getWallet]
          updatedWallet.splice(getAFeeling, 1)

          setGetWallet(updatedWallet)
          enqueueSnackbar(walletAlertSuccess, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right"
            }
          })
        }
      })
      .catch((err) => {
        enqueueSnackbar(walletAlertError, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          }
        })
      })
  }
 
  const columns = [
    {
      field: "correo",
      headerName: intl.formatMessage({
        id: "contact.email",
        defaultMessage: "Email"
      }),
      width: 270,
      // flex:1,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      editable: true,
      renderCell: (e) => {
        return <Typography fontSize={"15px"}>{e.row.correo}</Typography>
      }
    },
    {
      field: "wallet",
      headerName: "Wallet",
      type: "number",
      width: 500,
      // flex:1,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      editable: true,
      renderCell: (e) => {
        return <Typography fontSize={"15px"}>{e.row.usuario}</Typography>
      }
    },
    {
      field: "delete",
      headerName: "Delete",
      type: "number",
      width: 10,
      flex:1,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      renderCell: (e) => {
        return (
          <Delete
            onClick={() => {
              setOpenModalConfirm(true)
              setUserDeleteModal(e.row)
            }}
            sx={{ cursor: "pointer" }}
            color="red"
          />
        )
      }
    },
   
  ]

  return (
    <>
    
    <Box sx={{ height: 400, width: "100%", marginTop: 5 }}>
 <DataGrid
 rows={getWallet}
 columns={columns}
 getRowId={(e) => e._id.$oid}
 initialState={{
   pagination: {
     paginationModel: {
       pageSize: 5
     }
   }
 }}
 pageSizeOptions={[5]}
 checkboxSelection={false}
 disableRowSelectionOnClick
 sx={{
   display: "flex",
   justifyContent: "center",
   color: "white",
   border: "3px solid #333333",
   // borderRadius: "20px",
   "&. MuiDataGrid-row": {
     backgroundColor:'#1C1C1C',
     border: '0px',
   },
   "& div": {
     borderRadius: '0px',
     border: '0' 
   },
   "& .super-app-theme--cell": {
     display: "flex",
     justifyContent: "center",
     borderBottom: "1px solid #333333",
     backgroundColor:'#1C1C1C',
     "&:hover": {
      backgroundColor: '#2E2E2E'
   }
   },
   "& .MuiToolbar-root": {
     color: "white"
   },
   "& MuiTablePagination-actions": {
     color: "white"
   },
   "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
     width: "5px",
     height: "10px"
   },
   "& .MuiDataGrid-columnHeaderTitle ": {
     color: "white",
     fontWeight: "bold"
   },
   "& .MuiDataGrid-columnHeaders": {
     backgroundColor: "#010101",
     borderBottom: "1px solid #333333",
   },
   "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
     backgroundColor: "#2731C8",
     borderRadius: "20px"
   },
   "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
     background: "#12175f"
   },
   "& .MuiDataGrid-menuIcon": {
     display: "none"
   },
   "& .MuiDataGrid-virtualScroller": {
     backgroundColor: "#010101",
   },
   "& .MuiDataGrid-footerContainer":{
     backgroundColor: "#010101",
   }
 }}
/>
    </Box>
    {openModalConfirm && (
        <Box sx={style}>
        <Typography
          color={"white"}
          id="transition-modal-description"
          sx={{ mt: 3, fontSize: "20px" }}
          component="h4"
        >
          {intl.formatMessage({
            id: "modal.delete.title",
            defaultMessage: "Are you sure you want to delete this user?",
          })}
        </Typography>
        <Box mt={4} display={"flex"} flexDirection={"row"} gap={4}>
          <Button
            onClick={() => setOpenModalConfirm(false)}
            sx={{
              backgroundColor: "red",
              color: "white",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#8B0000",
                color: "white",
              },
            }}
          >
            {intl.formatMessage({
              id: "button.cancel.alertLink",
              defaultMessage: "Close",
            })}
          </Button>
          <Button
            sx={buttonOk}
            onClick={() => {
              putUser(userDeleteModal.wallet);
              setOpenModalConfirm(false);
            }}
          >
            {intl.formatMessage({
              id: "modal.delete.user",
              defaultMessage: "Delete",
            })}
          </Button>
        </Box>
      </Box>
    )
}
  </>
  )
}



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: { xs: "90%", sm: "90%", md: "90%", lg: "60%", xl: "45%" },
  bgcolor: "#000446",
  boxShadow: 24,
  p: 4,
  border: "1px solid #00B8FF",
  display: "flex",
  justifyContent: "start",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
};



const buttonOk = {
  backgroundColor: "#6B78B8",
  height: "100%",
  borderRadius: "20px",
  color: "white",
  "&:hover": {
    backgroundColor: "#2731C8",
    color: "white",
  },
};
